
import "./../../assets/styles/OthersBooks.css";

import React, { useMemo, useState } from "react";

import { playSong } from '../../utils/song'
import { songNames } from "../../enums/songs";
import { CHUNKS_LENGTH } from './consts'
import { chunkArray } from '../../utils/common'

const OthersBooks = (props) => {
  const [lastIndexShown, setLastIndexShown] = useState(0)
  
  const pages = useMemo(() => {
    const items = [...props.payload.listData].reverse()

    return chunkArray(items, CHUNKS_LENGTH)
  }, [props.payload.listData])

  const handleClick = list => e => {
    const totalItens = list.length;
    const id = e.target.id;

    for (let j = 0; j < totalItens; j++) {
      const element = list[j];
      if (id === element.bookId) {
        playSong(songNames.TAP)
        setTimeout(() => {
          props.onSlideChange(id);
          
          // aciona o scroll pro topo
          window.scrollTo(0,0)
        }, 200);
        // finaliza o "for"
        j = totalItens;
      }
    }
  }

  return (
    <div id="others_books_container_overflow" className="others_books_container_overflow">
      <div className="others_books_content">
        {pages.slice(0, lastIndexShown + 1).map((list, index) => (
          <React.Fragment key={index}>
            {list.map(item => (
              <div className="others_books_slide_wrapper" key={item.bookId}>
                <div
                  id={item.bookId}
                  onClick={handleClick(list)}
                  className="others_books_slide"
                  style={{ backgroundImage: `url(${item.bookCover})` }}
                />
                <div className="text-white">
                  <p id="availability">Baixe até {item.bookAvailability}</p>
                </div>
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>

      {lastIndexShown < pages.length - 1 ? (
        <button
          className="load_more"
          onMouseEnter={() => {
            playSong(songNames.TAP)
          }}
          onClick={() => {
            playSong(songNames.TAP)
            setLastIndexShown(prev => prev + 1)
          }}
        >
          VER MAIS
        </button>
      ) : null}
    </div>
  ) 
}

export default OthersBooks